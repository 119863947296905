<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action" v-if="isChanged">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Өөрчлөлт хадгалах</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" :disabled="isDisabled" @click="onPressSave('productForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header srection -->
        <custom-header title="Бүтээгдэхүүн нэмэх" />
        <!-- form error template -->
        <div class="form-error-container mb20" id="error-listener" ref="errorListener" v-if="errorChecker.length != 0">
          <header>Уучлаарай дутуу {{errorChecker.length}} зүйл байна</header>
          <ul class="error-list">
            <div v-for="(file, index) in errorChecker" :key="index">
              <li>{{file}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="productForm" :rules="productRules" ref="productForm" @submit.native.prevent="onPressSave('productForm')">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр ( Тoktokmall )" prop="name_mon" >
                        <el-input v-model="productForm.name_mon" placeholder="Говь ноолууран цамц"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи нэр" prop="name_eng" >
                        <el-input v-model="productForm.name_eng" placeholder="Gobi sweater"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Бүтээгдэхүүний sku ( Харилцагч )">
                    <el-input v-model="productForm.supplier_sku" type="text" placeholder="Бүтээгдэхүүний sku"></el-input>
                  </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Бүртгэлийн нэр ( Харилцагч )">
                    <el-input v-model="productForm.register_name" placeholder="Барааны бүртгэлийн нэр"></el-input>
                  </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="Монгол тайлбар" prop="description_mon">
                    <vue-editor v-model="productForm.description_mon"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Англи тайлбар" prop="description_eng">
                    <vue-editor v-model="productForm.description_eng"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Категори" prop="category_id" multiple class="position-relative">
                    <tree-selector v-model="productForm.category_id" :treeData="categories" multiple width="100%" />
                  </el-form-item>
                  <el-form-item label="Үндсэн категори" prop="main_category_id" class="position-relative">
                    <el-select v-model="productForm.main_category_id" width="100%">
                      <el-option v-for="category in categories" :key="category.uniq_id" :value="category.uniq_id" :label="category.name_mon">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item label="Үндсэн категори" prop="main_category_id" class="position-relative">
                    <el-input v-model="productForm.sort" width="100%">
                    </el-input>
                  </el-form-item> -->
                </div>
              </div>
              <!-- Media section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /бүтээгдэхүүн/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImages"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'image', null)"
                    :on-change="(file) => getFile(file, 'image')">
                    <span>1020x1020</span>
                  </el-upload>
                  <loader :active="isUploading" />
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /сав, баглаа/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImagesPackage"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'image', null)"
                    :on-change="(file) => getFile(file, 'package')">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <loader :active="isUploadingPackage" />
                </div>
              </div>
              <!-- Price section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Татах үнэ" prop="get_price">
                        <el-input v-model="productForm.get_price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Борлуулах үнэ" prop="price">
                        <el-input v-model="productForm.price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                          <el-input placeholder="0%" v-model="percent">
                            <template slot="append">%</template>
                          </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row class="pb20">
                    <el-col :span="12">
                      <el-checkbox v-model="productForm.sale_check" @change="generateSaleCheck(productForm.sale_check)">Энэ хямдралтай бүтээгдэхүүн</el-checkbox>
                    </el-col>
                    <el-col :span="12" v-if="productForm.sale_check">
                      <el-checkbox @change="generateDiscountDate(isDiscountDate)" v-model="isDiscountDate">Хямдралын хугацаа оруулах</el-checkbox>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12" v-if="productForm.sale_check">
                      <el-form-item label="Хямдарсан үнэ">
                        <el-input v-model="productForm.discounted_price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="isDiscountDate">
                      <el-form-item label="Эхлэх өдөр">
                        <el-date-picker type="datetime" placeholder="Эхлэх огноо" value-format="yyyy-MM-dd HH:mm:ss" v-model="productForm.start_date_sale"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="productForm.sale_check">
                    <el-col :span="12">
                      <el-form-item label="Хямдралтай татах үнэ">
                        <el-input v-model="productForm.get_sale_price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="isDiscountDate">
                      <el-form-item label="Дуусах өдөр">
                        <el-date-picker type="datetime" placeholder="Дуусах огноо" value-format="yyyy-MM-dd HH:mm:ss"  v-model="productForm.end_date_sale"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Group order section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Групп захиалга</header>
                </div>
                <div class="panel-item">
                  <div class="mb20">
                    <el-checkbox v-model="productForm.is_group">Групп захиалга</el-checkbox>
                  </div>
                  <el-row :gutter="20" v-if="productForm.is_group">
                      <!-- <el-form :model="group" :rules="groupRules" ref="group"> -->
                        <el-col :span="12">
                          <el-form-item label="Хямдрал дуусах хугацаа /цаг/" prop="duration_time">
                            <el-input type="number" v-model="group.duration_time" placeholder="24">
                              <template slot="append">цаг</template>
                            </el-input>
                          </el-form-item>
                          <el-form-item label="Хямдрах төрөл">
                            <el-radio v-model="productForm.group_order_discount_type" label="percent">Хувиар</el-radio>
                            <el-radio v-model="productForm.group_order_discount_type" label="amount">Үнийн дүнгээр</el-radio>
                          </el-form-item>
                          <el-form-item label="Багадаа хэдэн ширхэг авч болох">
                            <el-input type="number" v-model="group.min_item_count" placeholder="1">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="Эхлэх хугацаа" prop="start_date">
                            <el-date-picker
                              value-format="yyyy-MM-dd HH:mm:ss"
                              v-model="group.start_date"
                              type="datetime"
                              placeholder="Эхлэх хугацаа">
                            </el-date-picker>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Хэдэн хүн" prop="user_count">
                            <el-input type="number" v-model="group.user_count" placeholder="10"></el-input>
                          </el-form-item>
                          <el-form-item label="Хямдралийн хувь" v-if="productForm.group_order_discount_type === 'percent'">
                            <el-input type="number" v-model.number="group.saled_percentage" placeholder="30%">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                          <el-form-item label="Хямдралийн дүн" v-if="productForm.group_order_discount_type === 'amount'">
                            <el-input type="number" v-model.number="group.saled_price" placeholder="5000₮">
                              <template slot="append">₮</template>
                            </el-input>
                          </el-form-item>
                          <el-form-item label="Ихдээ хэдэн ширхэг авч болох" prop="max_item_count">
                            <el-input type="number" v-model="group.max_item_count" placeholder="10">
                            </el-input>
                          </el-form-item>
                   <div class="pb20 pt30" >
                    <el-checkbox v-model="productForm.time_check">Дуусах хугацаа</el-checkbox>
                  </div>
                  <div class="variants-container pane" v-if="productForm.time_check">
                    <el-form-item prop="end_date">
                        <el-date-picker @input="$forceUpdate()"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="group.end_date"
                          type="datetime"
                          placeholder="Дуусах хугацаа">
                        </el-date-picker>
                      </el-form-item>
                  </div>
                        </el-col>
                      <!-- </el-form> -->
                  </el-row>
                </div>
              </div>
              <!-- Variants section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Бараа сонголт</header>
                  <div class="pb20">
                    <el-checkbox v-model="productForm.haveVariant">Энэ бүтээгдэхүүн олон сонголттой бараа</el-checkbox>
                  </div>
                </div>
                <div class="variants-container pane" v-if="productForm.haveVariant">
                  <div class="panel-item" v-for="(variant, index) in variantNames" :key="index">
                    <el-form-item :label="'Сонголт ' + (index + 1)">
                      <el-row :gutter="5">
                        <el-col :span="8">
                          <el-select
                            v-model="variant.name"
                            filterable
                            allow-create
                            default-first-option
                            @change="changeVariantSelect"
                            placeholder="Сонголт">
                            <el-option
                              v-for="(item, index) in options"
                              :key="index"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="12">
                          <el-select
                            class="variant-options-select"
                            v-model="variant.value"
                            multiple
                            filterable
                            allow-create
                            default-first-option
                            @change="changeVariant"
                            placeholder="Жиш : ноолуур, ноосон гэх мэт">
                            <el-option
                              v-for="(item, index) in optionValues"
                              :key="index"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="4">
                          <el-button type="default" @click="removeVariant(index)"><i class="el-icon-delete-solid"></i></el-button>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                  <div v-if = "variantNames.length <3" class="panel-item" style="border-bottom: 1px solid #dfe3e8; padding-bottom: 20px">
                    <el-button type="default" @click="addVariant()">Сонголт нэмэх <i class="el-icon-plus"></i></el-button>
                  </div>
                  <div class="panel-item" v-if="productForm.variants.length > 0">
                    <header>Урьдчилан харах<span> - {{productForm.variants.length}} төрөл</span></header>
                    <el-table
                      :data="productForm.variants"
                      style="width: 100%">
                      <el-table-column
                        label="Нэр"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                          {{scope.row.variant_name}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="150px"
                        label="Доод үлдэгдэл">
                        <template slot-scope="scope">
                          <el-input type="number" size="mini" v-model="scope.row.threshold_number" :min="0"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column align="center">
                        <template slot-scope="scope">
                          <el-upload
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            :auto-upload="false"
                            action=""
                            :file-list="scope.row.variant_image"
                             :on-remove="(file) => handleRemove(file, 'variant', scope.row)"
                            :on-change="(file) => getFileVariant(file, scope.row)">
                            <el-button size="small" :disabled="isUploadingVariantDisabled" type="primary">Зураг</el-button>
                          </el-upload>
                          <!-- <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImagesPackage"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'package')"
                    :on-change="(file) => getFile(file, 'package')">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                          <loader :active="scope.row.isUploadingVariant" />
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <!-- <el-form-item label="Barcode (ISBN, UPC, GTIN, etc.)" prop="barcode">
                    <el-input v-model="productForm.barcode" type="text"></el-input>
                  </el-form-item> -->
                </div>
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <div class="mb20">
                        <el-tooltip class="item" effect="dark" placement="bottom-start" visible-arrow>
                          <div slot="content">Цахим бараа гэдэг нь биет бус буюу тасалбар, кино, <br/> дуу хөгжим, купон, видео сургалт гэх мэт барааг хэлнэ.<br/> Энэ сонголтыг сонгосон тохиолдолд хүргэлтийн үнэ <br/> 0 болохыг анхаарна уу.</div>
                          <el-switch v-model="productForm.is_physical" active-text="Энэ нь биетэй бүтээгдэхүүн" inactive-text="Цахим бараа"></el-switch>
                        </el-tooltip>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="mb20">
                        <el-checkbox v-model="productForm.sell_check">Агуулахад байхгүй ч зарах</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item" v-if="productForm.is_physical">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Жин">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Weight" type="number">
                              <el-select v-model="productSize.weightSuffix" slot="append">
                                <el-option label="kg" value="kg"></el-option>
                                <el-option label="gram" value="g"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Урт">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Length" type="number">
                              <el-select v-model="productSize.lengthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Өргөн">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Width" type="number">
                              <el-select v-model="productSize.widthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Өндөр">
                        <el-input v-model="productSize.Height" type="number" class="input-with-select">
                          <template slot="append">
                            <el-select v-model="productSize.heightSuffix" placeholder="сонгох">
                              <el-option label="cm" value="cm"></el-option>
                              <el-option label="inch" value="inch"></el-option>
                            </el-select>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <!-- Product status section -->
              <div class="panel">
                <div class="panel-item">
                  <el-form-item label="Төлөв" prop="status">
                    <el-select v-model="productForm.status" class="block">
                      <el-option
                        v-for="(item, index) in statusOption"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                        :disabled="true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Tag сонгох">
                    <el-select v-model="productForm.tag_id" clearable class="block">
                      <el-option
                        v-for="(tag, index) in tagData"
                        :key="index"
                        :label="tag.name_mon"
                        :value="tag.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Brand section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Нийлүүлэгч & Брэнд</header>
                  <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                    <el-select
                      class="block"
                      v-model="productForm.supplier_id"
                      @change="onChangeSupplier"
                      filterable
                      placeholder="Таван богд"
                      :loading="supplierLoading">
                      <el-option
                        v-for="(item, index) in suggestedSupplier"
                        :key="index"
                        :label="`${item.supplier_monName} (${item.phone})`"
                        :value="item.supplier_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Брэнд" prop="brand_id" v-if="productForm.supplier_id != ''">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.brand_id"
                      filterable
                      class="block">
                      <el-option
                        v-for="(item, index) in brandOptions"
                        :key="index"
                        :label="item.brand_monName"
                        :value="item.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Агуулах" prop="warehouse_id" v-if="productForm.supplier_id != ''">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.warehouse_id"
                      filterable
                      multiple
                      class="block">
                      <el-option
                        v-for="(warehouse, indexWarehouse) in warehouses"
                        :key="indexWarehouse"
                        :label="warehouse.name_mon"
                        :value="warehouse.warehouse_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Properties section -->
              <div class="panel">
                <div class="panel-item pb20">
                  <header>Шинж чанар</header>
                  <el-form-item label="Нэр">
                    <el-select
                      v-model="property.name"
                      filterable
                      @change="onChangeProperty"
                      allow-create
                      class="block">
                      <el-option
                        v-for="(item, index) in propertiesName"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Утга">
                    <el-select
                      v-model="property.value"
                      filterable
                      allow-create
                      class="block">
                      <el-option
                        v-for="(item, index) in propertiesValue"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="default" class="block" @click="addProperty">Шинж нэмэх</el-button>
                  </el-form-item>
                </div>
                <div class="panel-item" v-for="(property, index) in productForm.properties" :key="index">
                  <div class="property-item">
                    <el-row :gutter="10" type="flex" align="middle">
                      <el-col :span="18">
                        <div>
                          <strong>{{property.name}}</strong>
                        </div>
                        <div>
                          <span class="text-color-secondary">{{property.value}}</span>
                        </div>
                      </el-col>
                      <el-col :span="6" class="text-right">
                        <i class="el-icon-delete cursor-pointer" @click="removeProperty(property)"></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
            <el-button type="success" :disabled="isDisabled" @click="onPressSave('productForm')">Хадгалах</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Устгах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import TreeSelector from '../../../components/common/treeSelector'
import Loader from '../../../components/common/loader'

export default {
  name: 'addProduct',
  components: {
    CustomHeader,
    TreeSelector,
    Loader,
    VueEditor
  },
  data () {
    return {
      warehouses: [],
      isDiscountDate: false,
      tagData: [],
      time_check: false,
      isDisabled: false,
      property: {
        name: '',
        value: ''
      },
      defaultProps: {
        parent: 'parent',
        value: 'uniq_id',
        label: 'name_mon',
        children: 'childrens'
      },
      isUploadingVariantDisabled: false,
      isLoading: false,
      isUploading: false,
      isUploadingPackage: false,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      percent: '',
      isNewForm: true,
      dialogVisible: false,
      categories: [],
      variantNames: [],
      tempImages: [],
      tempImagesPackage: [],
      options: [],
      optionValues: [],
      statusOption: [],
      isLoadBrand: false,
      brandOptions: [],
      suggestedSupplier: [],
      propertiesValue: [],
      propertiesName: [],
      supplierLoading: false,
      productSize: {
        Weight: '',
        Length: '',
        Width: '',
        Height: '',
        weightSuffix: 'kg',
        heightSuffix: 'cm',
        widthSuffix: 'cm',
        lengthSuffix: 'cm'
      },
      group: {
        user_count: '',
        saled_price: 0,
        saled_percentage: 0,
        min_item_count: '',
        max_item_count: '',
        end_date: '',
        start_date: '',
        duration_time: ''
      },
      productForm: {
        warehouse_id: [],
        start_date_sale: '',
        end_date_sale: '',
        get_sale_price: '',
        main_category_id: '',
        tag_id: 0,
        group_order_discount_type: 'percent',
        is_group: false,
        name_mon: '',
        name_eng: '',
        register_name: '',
        supplier_sku: '',
        description_mon: '',
        description_eng: '',
        price: '',
        get_price: '',
        sell_check: false,
        sale_check: false,
        discounted_price: '',
        barcode: '',
        haveVariant: true,
        variant_check: true,
        image: [],
        package_image: [],
        is_physical: true,
        supplier_id: '',
        brand_id: '',
        status: 'inactive',
        category_id: [],
        variants: [],
        properties: [],
        physical_size: {
          Weight: '',
          Length: '',
          Width: '',
          Height: ''
        }
      },
      groupRules: {
        max_item_count: [
          { required: true, message: 'Ширхэгээ оруулна уу', trigger: 'change' }
        ],
        duration_time: [
          { required: true, message: 'Та цагаа оруулна уу', trigger: 'change' }
        ],
        user_count: [
          { required: true, message: 'Хүний тоогоо оруулна уу', trigger: 'change' }
        ]
      },
      productRules: {
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'change' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'change' }
        ],
        description_mon: [
          { required: true, message: 'Монгол тайлбараа оруулна уу', trigger: 'change' }
        ],
        description_eng: [
          { required: true, message: 'Англи тайлбараа оруулна уу', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: 'Категорио сонгоно уу', trigger: 'change' }
        ],
        main_category_id: [
          { required: true, message: 'Үндсэн категорио сонгоно уу', trigger: 'change' }
        ],
        price: [
          { required: true, message: 'Үнээ оруулна уу', trigger: 'change' }
        ],
        // barcode: [
        //   { required: true, message: 'Баркод оруулна уу', trigger: 'change' }
        // ],
        get_price: [
          { required: true, message: 'Татах үнээ оруулна уу', trigger: 'change' }
        ],
        status: [
          { required: true, message: 'Төлөвөө сонгоно уу', trigger: 'change' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'change' }
        ],
        brand_id: [
          { required: true, message: 'Брэндээ сонгоно уу', trigger: 'change' }
        ],
        warehouse_id: [
          { type: 'array', required: true, message: 'Агуулахаа сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getTag(0, 1000)
    this.getCategory()
    this.getStatusOptions()
    this.getVariantName([])
    this.getPropertiesName([])
    this.filterSupplier()
  },
  computed: {
    isChanged () {
      const defaultData = {
        tag_id: 0,
        name_mon: '',
        name_eng: '',
        supplier_sku: '',
        register_name: '',
        description_mon: '',
        description_eng: '',
        price: '',
        get_price: '',
        sale_check: false,
        sell_check: false,
        discounted_price: '',
        barcode: '',
        haveVariant: true,
        variant_check: true,
        image: [],
        package_image: [],
        is_physical: true,
        brand_id: '',
        status: 'active',
        category_id: '',
        main_category_id: '',
        variants: [],
        properties: [],
        physical_size: {
          Weight: '',
          Length: '',
          Width: '',
          Height: ''
        }
      }
      return JSON.stringify(this.productForm) !== JSON.stringify(defaultData)
    },

    errorChecker () {
      var errors = []
      if (!this.isNewForm) {
        if (this.productForm.name_mon.length === 0) {
          errors.push('Монгол нэр оруулна уу')
        }
        if (this.productForm.name_eng.length === 0) {
          errors.push('Англи нэр оруулна уу')
        }
        if (this.productForm.register_name.length === 0) {
          errors.push('Бүртгэлийн нэр оруулна уу')
        }
        if (this.productForm.supplier_sku.length === 0) {
          errors.push('Бүтээгдэхүүний sku оруулна уу')
        }
        if (this.productForm.description_mon.length === 0) {
          errors.push('Монгол тайлбар оруулна уу')
        }
        if (this.productForm.description_eng.length === 0) {
          errors.push('Англи тайлбар оруулна уу')
        }
        if (this.tempImages.length === 0) {
          errors.push('Зураг оруулна уу')
        }
        if (this.productForm.category_id.length === 0) {
          errors.push('Категори оруулна уу')
        }
        if (this.productForm.get_price.length === 0) {
          errors.push('Татах үнэ оруулна уу')
        }
        if (this.productForm.price.length === 0) {
          errors.push('Борлуулах үнэ оруулна уу')
        }
        if (this.productForm.supplier_id.length === 0) {
          errors.push('Нийлүүлэгч оруулна уу')
        }
        if (this.productForm.brand_id.length === 0) {
          errors.push('Брэнд оруулна уу')
        }
        if (this.productForm.properties.length === 0) {
          errors.push('Шинж чанар оруулна уу')
        }
        if (this.productForm.haveVariant && this.productForm.variants.length === 0) {
          errors.push('Бүтээгдэхүүн сонголт оруулна уу')
        }
        if (this.productForm.sale_check && this.productForm.discounted_price === '') {
          errors.push('Бүтээгдэхүүний хямдарсан үнээ оруулна уу')
        }
        if (this.productForm.main_category_id === '') {
          errors.push('Үндсэн ангилалаа сонгоно уу')
        }
        if (this.productForm.warehouse_id.length === 0) {
          errors.push('Агуулахаа сонгоно уу')
        }
      }
      return errors
    },
    categoryId () {
      return this.productForm.category_id
    }
  },
  watch: {
    categoryId (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue) || newValue.length === 0) {
        this.getVariantName(newValue)
        this.getPropertiesName(newValue)
      }
    }
  },
  methods: {
    getWarehousesBySupplierId () {
      this.loading = true
      const body = {
        supplier_id: [this.productForm.supplier_id],
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },
    generateSaleCheck (data) {
      if (data === false) {
        this.productForm.get_sale_price = ''
        this.productForm.discounted_price = ''
        this.isDiscountDate = false
      }
    },
    generateDiscountDate (data) {
      if (data === false) {
        this.productForm.start_date_sale = ''
        this.productForm.end_date_sale = ''
      }
    },
    getTag (from, size) {
      const query = '?from=' + from + '&size=' + size
      services.getTag(query).then((response) => {
        this.tagData = response.datas
      })
    },
    onChangeProperty (value) {
      const tempOptions = []
      services.getPropertiesValue(value).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach(element => {
            tempOptions.push({
              value: element.properties_value,
              label: element.properties_value
            })
          })
        }
      })
      this.propertiesValue = tempOptions
    },

    getPropertiesName (category) {
      const body = {
        is_category: category.length > 0,
        categories_id: category
      }
      const tempOptions = []
      services.getPropertiesName(body).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach(element => {
            tempOptions.push({
              value: element.properties_name,
              label: element.properties_name
            })
          })
        }
      })
      this.propertiesName = tempOptions
    },
    getVariantName (category) {
      const body = {
        is_category: category.length > 0,
        categories_id: category
      }
      services.getOptionName(body).then(response => {
        let tempOptions = []
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach((element, index) => {
            tempOptions.push({
              value: element,
              label: element
            })
          })
        } else {
          tempOptions = [{
            value: 'Хэмжээ',
            label: 'Хэмжээ'
          }, {
            value: 'Өнгө',
            label: 'Өнгө'
          }, {
            value: 'Материал',
            label: 'Материал'
          }, {
            value: 'Загвар',
            label: 'Загвар'
          }, {
            value: 'Гарчиг',
            label: 'Гарчиг'
          }]
        }
        let isReady = true
        this.variantNames.forEach(element => {
          if (element.value.length !== 0) {
            isReady = false
          }
        })
        if (isReady) {
          this.variantNames = [{
            name: tempOptions[0].value,
            value: []
          }]
          this.changeVariantSelect(tempOptions[0].value)
        }
        this.options = tempOptions
      })
    },
    filterSupplier () {
      services.searchSupplier('contract_accepted').then(response => {
        if (response.datas && Array.isArray(response.datas)) {
          this.suggestedSupplier = response.datas
        } else {
          this.suggestedSupplier = []
        }
      })
    },
    handleRemove (file, type, data) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else if (type === 'package') {
        this.tempImagesPackage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesPackage.splice(tempId, 1)
      } else if (type === 'variant') {
        this.productForm.variants.forEach((element) => {
          element.variant_image.forEach((el, index) => {
            if (el.uid === file.uid) {
              tempId = index
            }
          })
          if (data.variant_name === element.variant_name || data !== null) {
            data.variant_image.splice(tempId, 1)
          }
        })
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    getFile (file, type) {
      handlers.checkImageSize(file, type === 'image', 1020, 1020).then(() => {
        if (type === 'image') {
          this.isUploading = true
        } else if (type === 'package') {
          this.isUploadingPackage = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'image') {
              this.isUploading = false
            } else if (type === 'package') {
              this.isUploadingPackage = false
            }
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              } else if (type === 'package') {
                this.tempImagesPackage.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type, null)
        this.$message.error(error.message)
      })
    },

    getFileVariant (file, data) {
      handlers.checkImageSize(file, true, 1020, 1020).then(() => {
        data.isUploadingVariant = true
        this.isUploadingVariantDisabled = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (response.status === 'success') {
              data.isUploadingVariant = false
              this.isUploadingVariantDisabled = false
              var tempData = JSON.parse(JSON.stringify(this.productForm.variants))
              tempData.forEach((element) => {
                if (element.variant_name === data.variant_name) {
                  var tempImages = element.variant_image ? element.variant_image : []
                  tempImages.push({
                    url: response.url
                  })
                  element.variant_image = tempImages
                }
              })
              this.productForm.variants = tempData
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, 'variant', null)
        this.$message.error(error.message)
      })
    },

    getCategory () {
      services.getCategoriesAsTree().then(data => {
        data.forEach(element => {
          element.label = element.name_mon
          element.id = element.uniq_id
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            element.childrens.forEach(el => {
              el.label = el.name_mon
              el.id = el.uniq_id
              if (Object.prototype.hasOwnProperty.call(el, 'childrens')) {
                el.childrens.forEach(el1 => {
                  el1.label = el1.name_mon
                  el1.id = el1.uniq_id
                  if (Object.prototype.hasOwnProperty.call(el1, 'childrens')) {
                    el1.childrens.forEach(el2 => {
                      el2.label = el2.name_mon
                      el2.id = el2.uniq_id
                    })
                    el1.children = el1.childrens
                  }
                })
                el.children = el.childrens
              }
            })
            element.children = element.childrens
          }
        })
        this.categories = data
      })
    },
    getStatusOptions () {
      const options = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
      this.statusOption = options
      this.productForm.status = options[1].value
    },
    onChangeSupplier (id) {
      this.isLoadBrand = true
      this.getWarehousesBySupplierId(id)
      services.getBrandsBySupplierId(id).then(response => {
        this.isLoadBrand = false
        if (response.status === 'success') {
          this.brandOptions = response.data.datas
          this.productForm.supplier_id = id
          this.productForm.brand_id = ''
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    },
    addSuffix (data) {
      var subData = {}
      subData.Weight = `${data.Weight} ${data.weightSuffix}`
      subData.Height = `${data.Height} ${data.heightSuffix}`
      subData.Width = `${data.Width} ${data.widthSuffix}`
      subData.Length = `${data.Length} ${data.lengthSuffix}`
      return subData
    },
    onPressSave (formName) {
      this.isNewForm = false
      if (this.errorChecker.length === 0) {
        this.isDisabled = true
      }
      const tempBodyData = JSON.parse(JSON.stringify(this.productForm))
      tempBodyData.physical_size = this.addSuffix(this.productSize)
      tempBodyData.price = parseFloat(tempBodyData.price)
      tempBodyData.get_price = parseFloat(tempBodyData.get_price)
      tempBodyData.discounted_price = tempBodyData.discounted_price !== '' ? parseFloat(tempBodyData.discounted_price) : 0
      tempBodyData.tag_id = tempBodyData.tag_id === '' ? 0 : tempBodyData.tag_id
      tempBodyData.start_date_sale = tempBodyData.start_date_sale === '' ? null : tempBodyData.start_date_sale
      tempBodyData.end_date_sale = tempBodyData.end_date_sale === '' ? null : tempBodyData.end_date_sale
      tempBodyData.get_sale_price = parseFloat(tempBodyData.get_sale_price)
      const a = (tempBodyData.price - tempBodyData.get_price)
      const b = (a / tempBodyData.get_price) * 100
      if (tempBodyData.get_price === 0 || tempBodyData.price === 0) {
        this.percent = 0
      } else {
        this.percent = parseFloat(b).toFixed(2)
      }
      if (tempBodyData.is_group === true) {
        tempBodyData.group = this.group
      }
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      tempBodyData.image = tempImages
      var tempImagesPackage = []
      this.tempImagesPackage.forEach(element => {
        tempImagesPackage.push(element.url)
      })
      tempBodyData.variants.forEach(variant => {
        const tempWarehouse = []
        tempBodyData.warehouse_id.forEach(warehouseId => {
          tempWarehouse.push({
            count: 0,
            warehouse_id: warehouseId
          })
        })
        variant.warehouse = tempWarehouse
      })
      tempBodyData.package_image = tempImagesPackage
      tempBodyData.variants = this.clearData(tempBodyData.variants)
      this.$refs[formName].validate((valid) => {
        if (valid && this.errorChecker.length === 0 && !this.isUploading && !this.isUploadingPackage) {
          this.isLoading = true
          services.addProduct(tempBodyData).then(data => {
            this.isLoading = false
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$router.go(-1)
              this.isDisabled = false
            }
          })
        } else {
          window.scrollTo(0, 0)
          return false
        }
      })
    },
    clearData (incomingVariants) {
      incomingVariants.forEach((variantElement) => {
        const returnData = []
        variantElement.variant_image.forEach((imageElement) => {
          returnData.push(imageElement.url)
        })
        variantElement.variant_image = returnData
      })
      return incomingVariants
    },
    removeProperty (property) {
      this.productForm.properties = this.productForm.properties.filter(item => property !== item)
    },
    addProperty (e) {
      if (this.property.name && this.property.value) {
        this.productForm.properties = [...this.productForm.properties, { name: this.property.name, value: this.property.value }]
        this.property.name = ''
        this.property.value = ''
      }
    },
    variantType (name, option) {
      return {
        isUploadingVariant: false,
        variant_name: name,
        variant_image: [],
        option: option
      }
    },
    changeVariant () {
      var tempVariants = []
      if (this.variantNames.length === 1) {
        this.variantNames[0].value.forEach(el => {
          tempVariants.push(
            this.variantType(
              el,
              [{
                name: this.variantNames[0].name,
                value: el
              }]
            )
          )
        })
      } else if (this.variantNames.length === 2 && this.variantNames[0].name !== this.variantNames[1].name) {
        this.variantNames[0].value.forEach(el => {
          this.variantNames[1].value.forEach(el1 => {
            tempVariants.push(
              this.variantType(
                el + '/' + el1,
                [{
                  name: this.variantNames[0].name,
                  value: el
                }, {
                  name: this.variantNames[1].name,
                  value: el1
                }]
              )
            )
          })
        })
      } else if (this.variantNames.length === 3 && this.variantNames[0].name !== this.variantNames[2].name && this.variantNames[1].name !== this.variantNames[2].name) {
        this.variantNames[0].value.forEach(el => {
          this.variantNames[1].value.forEach(el1 => {
            this.variantNames[2].value.forEach(el2 => {
              tempVariants.push(
                this.variantType(
                  el + '/' + el1 + '/' + el2,
                  [{
                    name: this.variantNames[0].name,
                    value: el
                  }, {
                    name: this.variantNames[1].name,
                    value: el1
                  }, {
                    name: this.variantNames[2].name,
                    value: el2
                  }]
                )
              )
            })
          })
        })
      } else {
        this.alertReporter('Уучлаарай', 'Сонголтын нэр адилхан байна. Сонголтын нэрийг өөрчилнө үү.', 'error')
      }
      this.productForm.variants = tempVariants
    },
    addVariant () {
      var unused = null
      this.options.forEach(element => {
        if (!unused) {
          unused = element.value
        }
      })
      this.variantNames.push(
        {
          name: unused,
          value: []
        }
      )
      this.changeVariant()
    },
    changeVariantSelect (value) {
      const body = {
        option_name: value,
        categories_id: this.productForm.category_id
      }
      services.getOptionValue(body).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          const tempValues = []
          response.data.forEach(element => {
            if (element && element.name_mon && element.hex_code) {
              tempValues.push({
                value: element.name_mon,
                code: element.hex_code,
                label: element.name_mon
              })
            } else {
              tempValues.push({
                value: element,
                label: element
              })
            }
          })
          this.optionValues = tempValues
        }
      })
    },
    removeVariant (index) {
      this.variantNames.splice(index, 1)
      this.changeVariant()
    },
    mchange (event) {
      console.log(event)
    }
  }
}
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
